import React, { useState } from "react";
import "./assets/css/BabyCommon.css";
import "./assets/css/YokaBaby.css";

const YokaBaby = () => {
    return (
      <>
       <div className="yoka_baby_wrap">
      
       </div>
      </>
    );
  };
  
  export default YokaBaby;