//여기에 클라이언트 홈을 만들것입니다. =>  이경민
import React, { useState } from "react";

//컴포넌트
import TopNav from "components/Home/TopNav";
import BabyAnimationZone from "components/Home/BabyAnimationZone";
import YokaBaby from "components/Home/YokaBaby";

const Home = () => {
  return (
    <>
      {/* nav 바 */}
      <TopNav />
      {/* 아기메인(임신중인가 육아중인가에따라서 다른 컴포넌트) 

       : webGL(아이가 흔들흔들거리는것) / 주수계산/ 디데이 계산 / 랜덤 문구(터치시 변경) 
       */}
      <BabyAnimationZone />
      <YokaBaby />
      {/* 카테고리: 스와이퍼(넘기는 process바 등을 추가해야함) */}
      {/* 퀵메뉴 : 드롭앤드랍 */}
      {/* 배너:관리자가 관리할수있어야함 */}
      {/* 혜택팡팡 : 뭔혜택이 알려주는 카테고리 : 게시판의 목록을 단편적으로 보여줌 */}
      {/* 배너2:관리자가 관리할수있어야함 */}
      {/* 독 bar */}
    </>
  );
};

export default Home;
