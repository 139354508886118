

export const COLUMNS = [
    {
        Header : 'Id',
        accessor : 'id'
    },
    {
        Header : 'First Name',
        accessor : 'first_name'
    },
    {
        Header : 'Last Name',
        accessor : 'last_name'
    },
    {
        Header : 'Email',
        accessor : 'email'
    },
    {
        Header : 'Gender',
        accessor : 'gender'
    }
];