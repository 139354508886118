import React, { useState } from "react";
import { Route, Routes, Link, BrowserRouter } from "react-router-dom";
import "assets/css/common.css";
import LoginPage from "pages/Client/LoginPage";
// import SignUpMain from "pages/Client/SignUpMain";
import Home from "pages/Client/Home";
import DaumPostCode from "components/daum_postcode/DaumPostCode";

const Client = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        {/* <Route path="/SignUpMain/*" element={<SignUpMain />} /> */}
        <Route path="/Home/*" element={<Home />} />
      </Routes>
    </>
  );
};

export default Client;
