import { createSlice } from "@reduxjs/toolkit";
import { customerInfoInterface } from "static/interface/types";

const initial_state: customerInfoInterface = {
  plan: 0,
  obstetricsVisits: "",
  obstetricsName: "",
  dueDate: "",
  pregnancyWeeks: 0,
  numOfChild: "",
  email: "",
  password1: "",
  password2: "",
  postCode: "",
  address: "",
  addressDetail: "",
  subscriptionPath: "",
  signupGreetings: "",
  recommender: "",
};

export const signupInfoSlice = createSlice({
  name: "signupInfo",
  initialState: initial_state,
  reducers: {
    setPlan(state, action) {
      state.plan = action.payload.plan;
    },
    setObstetricsVisits(state, action) {
      state.obstetricsVisits = action.payload.obstetricsVisits;
    },
    setObstetricsName(state, action) {
      state.obstetricsName = action.payload.obstetricsName;
    },
    setDueDate(state, action) {
      state.dueDate = action.payload.dueDate;
    },
    setPregnancyWeeks(state, action) {
      state.pregnancyWeeks = action.payload.pregnancyWeeks;
    },
    setNumOfChild(state, action) {
      state.numOfChild = action.payload.numOfChild;
    },
    setEmail(state, action) {
      state.email = action.payload.email;
    },
    setPassword1(state, action) {
      state.password1 = action.payload.password1;
    },
    setPassword2(state, action) {
      state.password2 = action.payload.password2;
    },
    setPostCode(state, action) {
      state.postCode = action.payload.postCode;
    },
    setAddress(state, action) {
      state.address = action.payload.address;
    },
    setAddressDetail(state, action) {
      state.addressDetail = action.payload.addressDetail;
    },
    setSubscriptionPath(state, action) {
      state.subscriptionPath = action.payload.subscriptionPath;
    },
    setSignupGreetings(state, action) {
      state.signupGreetings = action.payload.signupGreetings;
    },
    setRecommender(state, action) {
      state.recommender = action.payload.recommender;
    },
  },
});

export const signupInfoActions = signupInfoSlice.actions;
export default signupInfoSlice.reducer;
