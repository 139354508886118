import { useEffect, useRef, useState } from "react";
import { client } from "static/ts/tools";
import { filterType, textItemInterface } from "../static/interface/PushFilter.interface";
import TargetViewer from "./TargetViewer";

const TargetFilterCopyPaste = (
    {
        setTokens,
        setTokenLoading,
    }:{
        setTokens: React.Dispatch<React.SetStateAction<string[]>>;
        setTokenLoading: React.Dispatch<React.SetStateAction<boolean>>;
    }
) => {
    const [filterValue, setFilterValue] = useState<filterType>("phone");

    const [targetText, setTargetText] = useState<string>("");
    const [targetTextList, setTargetTextList] = useState<textItemInterface[]>([]);

    const [isRemoveDuplication, setIsRemoveDuplication] = useState<boolean>(true);

    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const formatPhoneNumber = (phoneNumber: string): string => {
        // 하이픈 제거
        const cleanedNumber = phoneNumber.replace(/-/g, '');
      
        // 형식에 맞게 하이픈 추가
        const formattedNumber = `${cleanedNumber.substring(0, 3)}-${cleanedNumber.substring(3, 7)}-${cleanedNumber.substring(7)}`;
      
        return formattedNumber;
    }

    const initInputState = () => {
        setTargetText("");
        setTargetTextList([]);
    }

    const getTokenOnClick = () => {
        setTokenLoading(true);

        let context = null;

        const clearDataList = targetTextList.filter((item) => item.is_clear);

        if(filterValue === "name"){
            context = {
                name: clearDataList.map((item) => item.clear_text)
            }
        }else if(filterValue === "nick"){
            context = {
                nick: clearDataList.map((item) => item.clear_text)
            }
        }else if(filterValue === "email"){
            context = {
                email: clearDataList.map((item) => item.clear_text)
            }
        }else if(filterValue === "phone"){
            context = {
                phoneNumber: clearDataList.map((item) => formatPhoneNumber(item.clear_text))
            }
        }

        console.log(context);

        if(context){
            client
            .post("/api/member/filter/getToken", context)
            .then((response) => {
                console.log(response)

                const data = response.data;
                let count: number = 0;
                const nomal_tokens: string[] = [];

                for (const d of data) {
                    if (d.appId !== "" && d.appId.length === 36) {
                        nomal_tokens.push(d.appId);
                    } else {
                        count = count + 1;
                    }
                }

                console.log(`${nomal_tokens.length}개의 정상 토큰`);
                console.log(`${count}건의 문제 있는 토큰값 확인`);
                console.log(`${nomal_tokens.length + count} 총합`);
                setTokens(nomal_tokens);
            })
            .catch((error) => {
                alert("문제가 발생했습니다.")
                console.error(error); // 에러 출력
            })
            .finally(()=>{
                setTokenLoading(false);
            })
        }
    };

    // 검증 및 데이터 변환
    const valueManager = (type:filterType , value: string, index: number) => {
        const result: textItemInterface = {
            is_clear: false,
            is_duplication: false,
            index: index,
            input_text: value,
            clear_text: "",
            msg: "",
        }
        
        if(type === "name" || type === "nick"){
            const remove_space_value = value.trim();
            result.clear_text = remove_space_value;

            if(remove_space_value.length === 0){
                result.is_clear = false;
                result.msg = "빈 값";
            }else{
                result.is_clear = true;
                result.msg = "success";
            }

        }else if(type === "email"){
            const remove_space_value = value.trim();
            result.clear_text = remove_space_value;

            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const check_reuslt = regex.test(remove_space_value);

            if(check_reuslt){
                result.is_clear = true;
                result.msg = "success"
            }else{
                result.is_clear = false;
                result.msg = "이메일 조건에 맞지 않는 값"
            }

        }else{
            const remove_space_value = value.replace(/[\s-]/g, '');
            result.clear_text = remove_space_value;

            const regex = /^010\d{8}$/;
            const check_reuslt = regex.test(remove_space_value);

            if(check_reuslt){
                result.is_clear = true;
                result.msg = "success"
            }else{
                result.is_clear = false;
                result.msg = "번호 조건에 맞지 않는 값"
            }

        }

        return result;
    }

    // 중복 데이터 처리
    function markDuplications(items: textItemInterface[]): textItemInterface[] {
        // Clear the duplication flags
        items.forEach(item => item.is_duplication = false);
    
        // Use a map to count occurrences of each clear_text
        const textCountMap = new Map<string, number>();
    
        items.forEach(item => {
            const text = item.clear_text;
            if (textCountMap.has(text)) {
                textCountMap.set(text, textCountMap.get(text)! + 1);
            } else {
                textCountMap.set(text, 1);
            }
        });
    
        // Mark duplications
        items.forEach(item => {
            if (textCountMap.get(item.clear_text)! > 1) {
                item.is_duplication = true;
            }
        });
    
        return items;
    }

    // 중복된 데이터 제거
    function removeDuplicates(items: textItemInterface[]): textItemInterface[] {
        const uniqueItemsMap = new Map<string, textItemInterface>();
    
        items.forEach(item => {
            if (!uniqueItemsMap.has(item.clear_text)) {
                uniqueItemsMap.set(item.clear_text, item);
            }
        });
    
        return Array.from(uniqueItemsMap.values());
    }

    const isFilterType = (value: any): value is filterType => {
        return ["name", "nick", "phone", "email"].includes(value);
    };

    useEffect(() => {
        const split_result = targetText.trim() === "" ? [] : targetText.trim().split('\n');

        console.log(`split_result length : ${split_result.length}`)
        const valueManagerResult = split_result.map((item, index) => valueManager(filterValue, item, index)); 
        const markDuplicationsResult = markDuplications(valueManagerResult);

        if(isRemoveDuplication){
            setTargetTextList(removeDuplicates(markDuplicationsResult));
        }else{
            setTargetTextList(markDuplicationsResult);
        }

    }, [filterValue, targetText, isRemoveDuplication])

    return (
        <div>
            <div>
                <label>
                    <select 
                        value={filterValue} 
                        onChange={(e)=>{
                            const value = e.target.value;
                            if (isFilterType(value)) {
                                setFilterValue(value);
                            }else{
                                alert("Invalid filter type");
                            }
                        }}
                    >
                        <option value={"name"}>이름</option>
                        <option value={"nick"}>닉네임</option>
                        <option value={"phone"}>휴대전화</option>
                        <option value={"email"}>이메일</option>
                    </select>
                </label>
            </div>
            <div>
                <textarea
                    className="normal_textarea"
                    value={targetText}
                    onChange={(e)=>{setTargetText(e.target.value)}}
                    ref={textareaRef}
                >{targetText}</textarea>
            </div>
            {
                targetTextList.length > 0
                &&  <TargetViewer 
                        targetTextList={targetTextList}
                        isRemoveDuplication={isRemoveDuplication} 
                        setIsRemoveDuplication={setIsRemoveDuplication} 
                    />
            }
            {
                targetTextList.length > 0
                ?   <>
                        {
                            targetTextList.filter((item) => item.is_clear === true).length > 0
                            &&  <div>
                                    <button
                                        className="normal_btn margin_top_10"
                                        onClick={()=>{
                                            getTokenOnClick();
                                        }}
                                    >
                                        토큰가져오기
                                    </button>
                                </div>  
                        }
                        <div>
                            <button
                                className="red_btn margin_top_10 margin_bottom_10"
                                onClick={()=>{
                                    if(targetTextList.length > 0){
                                        if(!window.confirm("추가된 PUSH 타겟 정보가 초기화됩니다.")){
                                            return ;
                                        }

                                        initInputState();
                                    }
                                }}
                            >
                                목록 비우기
                            </button>
                        </div>
                    </>
                :   null
            }

        </div>
    )
}

export default TargetFilterCopyPaste;