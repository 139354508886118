import React, { useState } from "react";
import "./assets/css/BabyCommon.css";
import "./assets/css/ImsinBaby.css";

const BabyAnimationZone = () => {
  return (
    <>
      <div className="baby_wrap imsin_baby_wrap">
        {/* 임신중일때 보이는 화면입니다 */}
        {/* baby_wrap 이 여러개 보여야할것같으데 이걸 swiper로 하나 아니면 다른걸로 하나 */}
        <div className="baby_state">
          <div className="inner">
            <div className="jusu_info">
              <div>
                <span className="baby_name">럽맘이</span> <span className="imsin_week">32주차</span> <span className="imsin_date">3일</span>
              </div>
              <div className="d_day">D-20</div>
            </div>
            <div className="download_btn">
              <img src="*" alt="다운로드버튼" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BabyAnimationZone;
