import React, { useState } from "react";
import "./assets/css/TopNav.css";

const TopNav = () => {
    return (
      <>
       <div className="top_nav_wrap">
        <div className="hamberger">메뉴</div>
        <div className="toggle_button">임신인지 육아인지 토글버튼</div>
        <div>
            <div className="locate">위치</div>
            <div className="locate">알람</div>
        </div>
       </div>
      </>
    );
  };
  
  export default TopNav;