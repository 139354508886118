import { useEffect, useState } from "react";
import { Route, Routes, Link, BrowserRouter } from "react-router-dom";
import Modal from "react-modal";

import { client } from "static/ts/tools";
import "./App.css"; //공통 css로 사용
import Client from "pages/Client/Client";
import Manager from "pages/Manager/Manager";
import DevmodeMain from "pages/Test/DevmodeMain";

Modal.setAppElement("#root");

function App() {
  const [login, setLogin] = useState<boolean>(false);

  useEffect(() => {
    //  개발 모드 토큰 설정
    if (process.env.NODE_ENV === "development") {
      if (process.env.ACCESS_TOKEN) {
        sessionStorage.setItem("user_token", process.env.ACCESS_TOKEN);
      }
      setLogin(true);
    } else {
      const local_token = localStorage.getItem("user_token");
      const session_token = sessionStorage.getItem("user_token");
      let is_have_token: boolean = false;

      let target_token: string = "";
      //  세션이나 쿠키에 토큰값이 있는지 검증
      if (session_token !== undefined && session_token !== null) {
        target_token = session_token;
        is_have_token = true;
      } else if (local_token !== undefined && local_token !== null) {
        target_token = local_token;
        is_have_token = true;
      }

      // if (is_have_token) {
      //   client
      //     .post("/api/auth/verify", { headers: { Authorization: `Bearer ${target_token}` } })
      //     .then((res) => {
      //       if ("아무튼 성공했다면") {
      //         sessionStorage.setItem("user_token", target_token);
      //         setLogin(true);
      //       } else if ("아무튼 실패했다면") {
      //         localStorage.clear();
      //         sessionStorage.clear();
      //         setLogin(false);
      //         alert("자동 로그인에 실패했습니다. 로그인을 다시 진행해주세요.");
      //       }
      //     })
      //     .catch((err) => {
      //       localStorage.clear();
      //       sessionStorage.clear();
      //       setLogin(false);
      //       alert("문제가 발생했습니다. 다시 로그인 해주세요.(문제가 반복되면 관리자에게 문의해주세요.)");
      //     });
      // }

      // //  아래부분은 토큰검증이 없어서 임시로 만든 부분임. 이후에 제거해야함.
      if (target_token.length > 0) {
        sessionStorage.setItem("user_token", target_token);
        setLogin(true); 
      }
      // ///////////////////////////////////////////////////////
    }
  }, []);
  //Please change the parent <Route path="/"> to <Route path="*">.
  if (process.env.NODE_ENV === "development") {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<DevmodeMain />} />
          <Route path="/Client/*" element={<Client />} />
          <Route path="/Manager/*" element={<Manager />} />
        </Routes>
      </div>
    );
  } else if (!login) {
    return (
      <div className="App">
        <Routes>
          {/* <Route path="/Home/*" element={<Client />} /> */}
          <Route path="/*" element={<Client />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Routes>
          <Route path="/*" element={<Manager />} />
        </Routes>
      </div>
    );
  }
}

export default App;
