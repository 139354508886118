import { useEffect, useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { Container, Content, ClosedSideBar, OpenSideBar } from "./styles";
import { decodedTokenInterface } from "static/interface/types";
//npm install react-icons
// import {
// 	MdSettings,
// 	BsArrowRight,
// 	BsArrowLeft,
// 	DiReact,
// 	RiLogoutCircleRLine
// } from "react-icons/all";

//import {IoNotificationsSharp} from "react-icons/io5";

import logoImg from "assets/images/icon.png";
import userImg from "assets/images/eu.jpg";
import hamberger_menu from "assets/images/sidemenu/hamberger_menu.png";
import hamberger_menu_x from "assets/images/sidemenu/hamberger_menu_x.png";
export function SideBar() {
  const [sideBar, setSideBar] = useState(false);
  const [userId, setUserId] = useState<string>("luvmom");

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      //	개발 모드
      setUserId("TEST_DEV_MODE");
    } else {
      const token: string | null = sessionStorage.getItem("user_token");
      if (token !== null) {
        const decode: decodedTokenInterface = jwt_decode(token);
        if (decode) {
          setUserId(decode.mb_id);
        }
      }
    }
  }, []);

  function handleChangeSideBar() {
    setSideBar((prevState) => !prevState);
  }
  return (
    <Container>
      <Content>
        {!sideBar ? (
          <ClosedSideBar>
            <nav>
              <button className="hamberger_menu" onClick={handleChangeSideBar}>
                <img src={hamberger_menu} alt="hamberger_menu" />
              </button>

              {/* Links principais do app */}
              <ul>
                <a href="/" title="Alguma coisa">
                  {/* <DiReact /> */}
                </a>
                <a href="/" title="Alguma coisa">
                  {/* <DiReact /> */}
                </a>
                <a href="/" title="Alguma coisa">
                  {/* <DiReact /> */}
                </a>
                <a href="/" title="Alguma coisa">
                  {/* <DiReact /> */}
                </a>
              </ul>
            </nav>
            <div>
              {/* Icones que pode não ser tão principais no app */}
              <ul>
                <a href="/" title="Notificações">
                  {/* <IoNotificationsSharp /> */}
                </a>
                <a href="/" title="Configurações">
                  {/* <MdSettings /> */}
                </a>
                <a href="/" title="Sair da conta">
                  {/* <RiLogoutCircleRLine /> */}
                </a>
              </ul>

              <span>
                <img src={userImg} alt="Eu" />
              </span>
            </div>
          </ClosedSideBar>
        ) : (
          <OpenSideBar>
            <section>
              <nav>
                <span>
                  <button onClick={handleChangeSideBar}>
                    <img src={hamberger_menu_x} alt="hamberger_menu" />
                  </button>
                </span>
                <div>
                  <img src={logoImg} alt="Eu" />
                  <h1>{userId}</h1>
                </div>

                <div>
                  <button
                    className="logout_btn"
                    onClick={() => {
                      if (window.confirm("로그아웃 하시겠습니까?")) {
                        sessionStorage.clear();
                        localStorage.clear();
                        document.location.href = "/";
                      }
                    }}
                  >
                    로그아웃
                  </button>
                </div>

                <ul>
                  <li>
                    <Link to="PushMain">푸시</Link>
                  </li>
                  <li>
                    <Link to="PushHistory">푸시내역</Link>
                  </li>
                  {/* <li>
                    <Link to="pushtable">박람회관리</Link>
                  </li> */}
                  {/* <li>
										<Link to="/Home/Login">로그인페이지<br/>(클라이언트단)</Link>
									</li> */}
                  {/* <li>
										<Link to="/Home/SignUpMain">회원가입페이지<br/>(클라이언트단)</Link>
									</li> */}
                </ul>
              </nav>
            </section>
            <aside onClick={handleChangeSideBar} />
          </OpenSideBar>
        )}
      </Content>
    </Container>
  );
}
