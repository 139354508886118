const FilterWrap = (
    {
        activateFilter,
        setActivateFilter,
        option_list,
    }:{
        activateFilter: number;
        setActivateFilter: React.Dispatch<React.SetStateAction<number>>;
        option_list: string[];
    }
) => {
    return (
        <div className="filter_on_off">
            {
                option_list.map((item, index) => {
                    return(
                        <label>
                            <input
                                type={"radio"}
                                checked={activateFilter === index + 1 ? true : false}
                                name={"activateFilter"}
                                onChange={() => {
                                    setActivateFilter(index + 1);
                                }}
                            />
                            {item}
                        </label>
                    )
                })
            }
        </div>
    )
}

export default FilterWrap;