import { localDataInterface } from "../interface/types";

export const LOCATION_DATA: localDataInterface[] = [
  {
    main: "지역 전체",
    sub: [],
  },
  {
    main: "서울",
    sub: ["지역 전체", "종로구", "중구", "용산구", "성동구", "광진구", "동대문구", "중랑구", "성북구", "강북구", "도봉구", "노원구", "은평구", "서대문구", "마포구", "양천구", "강서구", "구로구", "금천구", "영등포구", "동작구", "관악구", "서초구", "강남구", "송파구", "강동구"],
  },
  {
    main: "경기",
    sub: ["지역 전체", "수원시", "장안구", "권선구", "팔달구", "영통구", "성남시", "수정구", "중원구", "분당구", "의정부시", "안양시", "만안구", "동안구", "부천시", "광명시", "평택시", "동두천시", "안산시", "상록구", "단원구", "고양시", "덕양구", "일산동구", "일산서구", "과천시", "구리시", "남양주시", "오산시", "시흥시", "군포시", "의왕시", "하남시", "용인시", "처인구", "기흥구", "수지구", "파주시", "이천시", "안성시", "김포시", "화성시", "광주시", "양주시", "포천시", "여주시", "연천군", "가평군", "양평군"],
  },
  {
    main: "인천",
    sub: ["지역 전체", "중구", "동구", "연수구", "남동구", "부평구", "계양구", "서구", "미추홀구", "강화군", "옹진군"],
  },
  {
    main: "대전",
    sub: ["지역 전체", "동구", "중구", "서구", "유성구", "대덕구"],
  },
  {
    main: "대구",
    sub: ["지역 전체", "중구", "동구", "서구", "남구", "북구", "수성구", "달서구", "달성군"],
  },
  {
    main: "광주",
    sub: ["지역 전체", "동구", "서구", "남구", "북구", "광산구"],
  },
  {
    main: "부산",
    sub: ["지역 전체", "중구", "서구", "동구", "영도구", "부산진구", "동래구", "남구", "북구", "해운대구", "사하구", "금정구", "강서구", "연제구", "수영구", "사상구", "기장군"],
  },
  {
    main: "울산",
    sub: ["지역 전체", "중구", "남구", "동구", "북구", "울주군"],
  },
  {
    main: "세종특별자치시",
    sub: ["지역 전체", "세종시"],
  },
  {
    main: "강원",
    sub: ["지역 전체", "춘천시", "원주시", "강릉시", "동해시", "태백시", "속초시", "삼척시", "홍천군", "횡성군", "영월군", "평창군", "정선군", "철원군", "화천군", "양구군", "인제군", "고성군", "양양군"],
  },
  {
    main: "충북",
    sub: ["지역 전체", "충주시", "제천시", "청주시", "상당구", "서원구", "흥덕구", "청원구", "보은군", "옥천군", "영동군", "진천군", "괴산군", "음성군", "단양군", "증평군"],
  },
  {
    main: "충남",
    sub: ["지역 전체", "천안시", "동남구", "서북구", "공주시", "보령시", "아산시", "서산시", "논산시", "계룡시", "당진시", "금산군", "부여군", "서천군", "청양군", "홍성군", "예산군", "태안군"],
  },
  {
    main: "전북",
    sub: ["지역 전체", "전주시", "완산구", "덕진구", "군산시", "익산시", "정읍시", "남원시", "김제시", "완주군", "진안군", "무주군", "장수군", "임실군", "순창군", "고창군", "부안군"],
  },
  {
    main: "전남",
    sub: ["지역 전체", "목포시", "여수시", "순천시", "나주시", "광양시", "담양군", "곡성군", "구례군", "고흥군", "보성군", "화순군", "장흥군", "강진군", "해남군", "영암군", "무안군", "함평군", "영광군", "장성군", "완도군", "진도군", "신안군"],
  },
  {
    main: "경북",
    sub: ["지역 전체", "포항시", "남구", "북구", "경주시", "김천시", "안동시", "구미시", "영주시", "영천시", "상주시", "문경시", "경산시", "군위군", "의성군", "청송군", "영양군", "영덕군", "청도군", "고령군", "성주군", "칠곡군", "예천군", "봉화군", "울진군", "울릉군"],
  },
  {
    main: "경남",
    sub: ["지역 전체", "진주시", "통영시", "사천시", "김해시", "밀양시", "거제시", "양산시", "창원시", "의창구", "성산구", "마산합포구", "마산회원구", "진해구", "의령군", "함안군", "창녕군", "고성군", "남해군", "하동군", "산청군", "함양군", "거창군", "합천군"],
  },
  {
    main: "제주특별자치도",
    sub: ["지역 전체", "제주시", "서귀포시"],
  },
];

export const COLUMNS = [
  // {
  //   Header: "NO",
  //   accessor: "no",
  // },
  {
    Header: "제목",
    accessor: "title",
  },
  {
    Header: "이미지",
    accessor: "picture",
  },
  {
    Header: "수신자 수",
    accessor: "clicked",
  },
  // {
  //   Header: "수신자 수",
  //   accessor: "delivered",
  // },
  {
    Header: "반응률 (클릭수)",
    accessor: "response_rate",
  },
  // {
  //   Header: "내용",
  //   accessor: "body",
  // },
  {
    Header: "url",
    accessor: "url",
  },
  // {
  //   Header: "발송 생성 시간",
  //   accessor: "createdTime",
  // },
  {
    Header: "발송 시간",
    accessor: "sendTime",
  },
  // {
  //   Header: "수신자 설정",
  //   accessor: "filter",
  // },
  // {
  //   Header: "발송자",
  //   accessor: "sender",
  // },
  // {
  //   Header: "상태",
  //   accessor: "status",
  // },
  {
    Header: "취소",
    accessor: "button",
  },
];

export const PAGE_SET = [10, 20, 30, 50];
