import { useEffect, useState } from "react";
import { textItemInterface } from "../static/interface/PushFilter.interface";
import FilterWrap from "./FilterWrap";
import CSS from "../static/css/targetViewer.module.css";

const TargetViewer = (
    {
        targetTextList,
        isRemoveDuplication,
        setIsRemoveDuplication, 
    }:{
        targetTextList: textItemInterface[];
        isRemoveDuplication: boolean;
        setIsRemoveDuplication: React.Dispatch<React.SetStateAction<boolean>>;
    }
) => {
    const [filterValue, setFilterValue] = useState<number>(1);
    const [filtedTargetTextList, setFiltedTargetTextList] = useState<textItemInterface[]>([]);

    useEffect(() => {
        if(filterValue === 1){
            setFiltedTargetTextList(targetTextList);
        }else if(filterValue === 2){
            setFiltedTargetTextList(targetTextList.filter((item) => item.is_clear === true));
        }else if(filterValue === 3){
            setFiltedTargetTextList(targetTextList.filter((item) => item.is_clear === false));
        }else{
            setFiltedTargetTextList([]);
        }
    }, [filterValue, targetTextList])

    return (
        <div className={CSS.l_target_viewer_main}>
            <div
                style={{
                    display: "flex"
                }}
            >
                <FilterWrap
                    activateFilter={filterValue}
                    setActivateFilter={setFilterValue}
                    option_list={["전체", "정상", "비정상"]}
                />
            </div>
            <label>
                <input 
                    type={"checkbox"}
                    checked={isRemoveDuplication} 
                    onChange={() => {
                        setIsRemoveDuplication((sign) => !sign)
                    }}
                />
                중복 데이터 제거
            </label>
            <div className={CSS.l_table_wrap}>
                <table className="table_style">
                    <thead>
                        <tr>
                            <th>no</th>
                            <th>입력값</th>
                            <th>인식된 값</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtedTargetTextList.length > 0
                            ?   filtedTargetTextList.map((item, index) => {
                                    const classNameList:string[] = [];    
                                    const errors:string[] = [];

                                    if(item.is_clear === false){
                                        classNameList.push("tr_warning");
                                        errors.push("사용할 수 없는 데이터");
                                    }

                                    if(item.is_duplication === true && isRemoveDuplication === false){
                                        classNameList.push("tr_duplication");
                                        errors.push("중복된 데이터");
                                    }

                                    return (
                                        <tr 
                                            className={classNameList.join(" ")}
                                            key={index}
                                        >
                                            <td>{item.index + 1}</td>
                                            <td>{item.input_text}</td>
                                            <td>{item.clear_text}</td>
                                            <td>
                                                <div>
                                                    {
                                                        errors.map((item, index) => {
                                                            return (
                                                                <div 
                                                                    key={index}
                                                                    style={{
                                                                        textWrap: "nowrap"
                                                                    }}
                                                                >
                                                                    {item}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    item.is_clear === false && item.msg
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            :   <div>
                                    값이 없습니다.
                                </div>
                        }
                    </tbody>
                </table>
            </div>
            {
                targetTextList.filter((item) => item.is_clear === true).length > 0
                ?   <div>{targetTextList.filter((item) => item.is_clear === true).length}개의 정상 항목에 대한 토큰을 가져옵니다.</div>
                :   <div>정상적으로 요청할 수 있는 항목이 없습니다.</div>
            }
        </div>
    )
}

export default TargetViewer;