import React, { useState, useEffect } from "react";
import "assets/css/common.css";
import PaginationTable from "components/fair_table/PaginationTable";

//axios 사용하기

type FairProps = {
  //message: string,
};

//React.FC<FairProps> 사용 지양

const Fair = () => {
  // let [modal, setModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <div className={"male_on_off"}>
        {/* on/off 남성가입자 제한 */}
        <input
          className={"check_box"}
          type="checkbox"
          onChange={(event) => {
            setIsChecked(event.currentTarget.checked);
          }}
          checked={isChecked}
        />
        {isChecked ? <span>남성가입자 제한 상태입니다</span> : null}
      </div>
      <PaginationTable />
    </>
  );
};

export default Fair;
