import React, { useEffect, useState } from "react";
import { pushFilterInterface, pushFilterStateInterface, letObjectInteface } from "static/interface/types";
import { LOCATION_DATA } from "static/contant/hard_data";
import { serchLocationListFC, client } from "static/ts/tools";
import "assets/css/pushFilter.css";
import TargetFilter from "./components/TargetFilter";
import TargetFilterCopyPaste from "./components/TargetFilterCopyPaste";
import FilterWrap from "./components/FilterWrap";

// const dumyDubscriptionPathList = ["박람회", "지인소개", "SNS", "블로그or카페", "인터넷 검색"];

const PushFilter: React.FC<pushFilterInterface> = ({ pushFilter, setPushFilter, activateFilter, setActivateFilter, setTokens, setTokenLoading }) => {

    const [locationSub, setLocationSub] = useState<string[]>([]);
    const [dubscriptionPathList, setDubscriptionPathList] = useState<string[]>([]);

    useEffect(() => {
            client
            .get("/api/admin/register-path")
            .then((res) => {
                const origin_data = res.data;
                if (origin_data !== undefined && origin_data !== null && typeof origin_data === "string") {
                    const after_edit_data = [...origin_data.replace(/[{}\[\]'" ]/g, "").split(",")];
                    setDubscriptionPathList(after_edit_data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(()=>{

            });
    }, []);

    const getTokenOnClick = () => {
        setTokenLoading(true);
        const context: letObjectInteface = {};
        if (activateFilter === 1) {
            for (const key of Object.keys(pushFilter)) {
                if (key === "event" || key === "phoneNumber") {
                    if ((pushFilter[key] as string[]).length > 0) {
                        context[key] = pushFilter[key] as string[];
                    }
                } else {
                    if ((pushFilter[key] as string) !== "") {
                        if (key === "address_main") {
                            context["address"] = pushFilter[key] as string;
                        } else if (key === "address_sub") {
                            context["addressDetail"] = pushFilter[key] as string;
                        }else{
                            context[key] = pushFilter[key] as string; 
                        }
                    }
                }
            }
        console.log(context,"context")

        }

        client
        .post("/api/member/filter/getToken", context)
        .then((response) => {
            const data = response.data;
            let count: number = 0;
            const nomal_tokens: string[] = [];

            for (const d of data) {
                if (d.appId !== "" && d.appId.length === 36) {
                    nomal_tokens.push(d.appId);
                } else {
                    count = count + 1;
                }
            }

            console.log(`${nomal_tokens.length}개의 정상 토큰`);
            console.log(`${count}건의 문제 있는 토큰값 확인`);
            console.log(`${nomal_tokens.length + count} 총합`);
            setTokens(nomal_tokens);
        })
        .catch((error) => {
            console.error(error); // 에러 출력
        })
        .finally(()=>{
            setTokenLoading(false);
        });
    };

    return (
        <div className="pushFilter_wrap">
            <div className="pushFilter_wrap_title">
                <label className="filter_title">필터</label>
                <FilterWrap 
                    activateFilter={activateFilter}
                    setActivateFilter={setActivateFilter}
                    option_list={[
                        "일반 필터 적용",
                        "전체 회원 대상 전송",
                        "전체 회원 및 비회원 대상 전송",
                        "특정 유저 대상 전송"
                    ]}
                />
            </div>

            <div className="filters">
                {
                    activateFilter === 1
                    ?   <>
                            <div>
                                <label>
                                    <span className="title">운영체제</span>
                                    <select
                                        onChange={(e) => {
                                        setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, operatingSystem: e.target.value }));
                                        }}
                                    >
                                        <option value={""}>전체</option>
                                        <option value={"android"}>android</option>
                                        <option value={"ios"}>ios</option>
                                    </select>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <span className="title">생년월일</span>
                                    <input
                                        value={pushFilter.birthDateStart.replace("T00:00:00.000Z", "")}
                                        type={"date"}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, birthDateStart: e.target.value }));
                                            } else {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, birthDateStart: `${e.target.value}T00:00:00.000Z` }));
                                            }
                                        }}
                                    />{" "}
                                    ~{" "}
                                    <input
                                        value={pushFilter.birthDateEnd.replace("T00:00:00.000Z", "")}
                                        type={"date"}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, birthDateEnd: e.target.value }));
                                            } else {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, birthDateEnd: `${e.target.value}T00:00:00.000Z` }));
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <span className="title">출산예정일</span>
                                    <input
                                        value={pushFilter.dueDateStart.replace("T00:00:00.000Z", "")}
                                        type={"date"}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, dueDateStart: e.target.value }));
                                            } else {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, dueDateStart: `${e.target.value}T00:00:00.000Z` }));
                                            }
                                        }}
                                    />{" "}
                                    ~{" "}
                                    <input
                                        value={pushFilter.dueDateEnd.replace("T00:00:00.000Z", "")}
                                        type={"date"}
                                        onChange={(e) => {
                                        if (e.target.value === "") {
                                            setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, dueDateEnd: e.target.value }));
                                        } else {
                                            setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, dueDateEnd: `${e.target.value}T00:00:00.000Z` }));
                                        }
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <span className="title">가입일</span>
                                    <input
                                        value={pushFilter.registerDateStart.replace("T00:00:00.000Z", "")}
                                        type={"date"}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, registerDateStart: e.target.value }));
                                            } else {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, registerDateStart: `${e.target.value}T00:00:00.000Z` }));
                                            }
                                        }}
                                    />{" "}
                                    ~{" "}
                                    <input
                                        value={pushFilter.registerDateEnd.replace("T00:00:00.000Z", "")}
                                        type={"date"}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, registerDateEnd: e.target.value }));
                                            } else {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, registerDateEnd: `${e.target.value}T00:00:00.000Z` }));
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <span className="title">최종접속일</span>
                                    <input
                                        value={pushFilter.lastAccessDateStart.replace("T00:00:00.000Z", "")}
                                        type={"date"}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, lastAccessDateStart: e.target.value }));
                                            } else {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, lastAccessDateStart: `${e.target.value}T00:00:00.000Z` }));
                                            }
                                        }}
                                    />{" "}
                                    ~{" "}
                                    <input
                                        value={pushFilter.lastAccessDateEnd.replace("T00:00:00.000Z", "")}
                                        type={"date"}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, lastAccessDateEnd: e.target.value }));
                                            } else {
                                                setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, lastAccessDateEnd: `${e.target.value}T00:00:00.000Z` }));
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <span className="title">이름(닉네임)</span>
                                    <input
                                        // onChange={(e) => {
                                        //     setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, name: e.target.value }));
                                        // }}
                                        onChange={(e) => {
                                            const inputValues = e.target.value;
                                            const valuesArray = inputValues.split(',');
                                        
                                            // 여기에서 유효성 검사 등을 수행할 수 있습니다.
                                        
                                            setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, name: valuesArray }));
                                        }}
                                    />
                                </label>

                                <label>
                                    <span className="title">전화번호</span>
                                    <input
                                        // onChange={(e) => {
                                        //     setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, phoneNumber: e.target.value }));
                                        // }}
                                        onChange={(e) => {
                                            const inputValues = e.target.value;
                                            const valuesArray = inputValues.split(',');
                                        
                                            // 여기에서 유효성 검사 등을 수행할 수 있습니다.
                                        
                                            setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, phoneNumber: valuesArray }));
                                        }}

                                    />
                                </label>
                                
                                <label>
                                    <span className="title">이메일</span>
                                    <input
                                        // onChange={(e) => {
                                        //     setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, email: e.target.value }));
                                        // }}
                                        onChange={(e) => {
                                            const inputValues = e.target.value;
                                            const valuesArray = inputValues.split(',');
                                        
                                            // 여기에서 유효성 검사 등을 수행할 수 있습니다.
                                        
                                            setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, email: valuesArray }));
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    <span className="title">성별</span>
                                    <select
                                        onChange={(e) => {
                                        setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, gender: e.target.value }));
                                        }}
                                    >
                                        <option value={""}>전체</option>
                                        <option value={"F"}>여성</option>
                                        <option value={"M"}>남성</option>
                                    </select>
                                </label>

                                <label>
                                    <span className="title">임신여부</span>
                                    <select
                                        onChange={(e) => {
                                        setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, pregnant: e.target.value }));
                                        }}
                                    >
                                        <option value={""}>전체</option>
                                        <option value={"prepare"}>임신계획중</option>
                                        <option value={"prepare_visit"}>방문예정</option>
                                        <option value={"during_pregnancy"}>임신중</option>
                                        <option value={"child_rearing"}>육아중</option>
                                    </select>
                                </label>

                                <label>
                                    <span className="title">주소</span>
                                    <select
                                        value={pushFilter.address_main}
                                        onChange={(e) => {
                                        setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, address_main: e.target.value, address_sub: "" }));
                                        if (e.target.value !== "") {
                                            const item = serchLocationListFC("main", e.target.value);
                                            if (item !== undefined) {
                                                setLocationSub(item.sub);
                                            }
                                        }
                                        }}
                                    >
                                        {LOCATION_DATA.map((item, index) => {
                                        if (item.main === "지역 전체") {
                                            return (
                                                <option key={index} value={""}>
                                                    {item.main}
                                                </option>
                                            );
                                        } else {
                                            return (
                                                <option key={index} value={item.main}>
                                                    {item.main}
                                                </option>
                                            );
                                        }
                                        })}
                                    </select>{" "}
                                    <select
                                        value={pushFilter.address_sub}
                                        disabled={pushFilter.address_main === "" ? true : false}
                                        onChange={(e) => {
                                            setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, address_sub: e.target.value }));
                                        }}
                                    >
                                        {locationSub.map((item, index) => {
                                        if (item === "지역 전체") {
                                            return (
                                                <option key={index} value={""}>
                                                    {item}
                                                </option>
                                            );
                                        } else {
                                            return (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            );
                                        }
                                        })}
                                    </select>
                                </label>

                                <label>
                                    <span className="title">가입경로</span>
                                    <select
                                        onChange={(e) => {
                                            setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, signUpSource: e.target.value }));
                                        }}
                                    >
                                        <option value="">전체</option>
                                        {
                                            dubscriptionPathList.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </label>

                                <label>
                                    <span className="title">가입경로</span>
                                    <select
                                        onChange={(e) => {
                                            setPushFilter((prevPushFilter: pushFilterStateInterface) => ({ ...prevPushFilter, signUpSource: e.target.value }));
                                        }}
                                    >
                                        <option value="">전체</option>
                                        {
                                            dubscriptionPathList.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </label>
                                <div>
                                    <button className="normal_btn" onClick={getTokenOnClick}>토큰가져오기</button>
                                    {/* <img src={require("static/img/233F6D505786DA870A.gif")} width={"17px"} hidden={!tokenLoading} /> */}
                                </div>
                            </div>
                        </>
                    :   null
                }

                {
                    activateFilter === 4
                    ?   
                        // <TargetFilter 
                        //     setTokens={setTokens}
                        //     setTokenLoading={setTokenLoading}
                        // />
                        <TargetFilterCopyPaste
                            setTokens={setTokens}
                            setTokenLoading={setTokenLoading}
                        />
                    :   null
                }
            </div>
        </div>
    );
};

export default PushFilter;
