import React, { useState, useEffect } from "react";
import { Route, Routes, Link, BrowserRouter } from "react-router-dom";
import "assets/css/common.css";
import Main from "pages/Manager/Main";
import PushMain from "pages/Manager/PushMain";
import PushHistory from "pages/Manager/PushHistory";
import { SideBar } from "components/side_bar";
import Fair from "pages/Manager/Fair";

const Manager = () => {
  useEffect(() => {
    // if(sessionStorage.getItem("user_token") === null || sessionStorage.getItem("user_token") === undefined){
    //   alert("로그인 정보가 없습니다. 로그인 해주세요.")
    //   document.location.href = "/"
    // }
  }, []);

  return (
    <div className="manager_page">
      <SideBar />
      <div className="main_wrap">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/PushMain" element={<PushMain />} />
          <Route path="/PushHistory" element={<PushHistory />} />
          <Route path="/Fair" element={<Fair />} />
        </Routes>
      </div>
    </div>
  );
};

export default Manager;
