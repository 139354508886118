import React, { useEffect, useState } from "react";
import "assets/css/login.css";
import "assets/css/reset.css";
import background from "assets/images/login/login_b_obj.png";

const LoginPage = () => {
  // useEffect(()=>{
  //   if(window.confirm("테스트를위해 sessionStorage를 제거하시겠습니까?")){
  //     sessionStorage.clear()
  //   }
  //   if(window.confirm("테스트를위해 localStorage를 제거하시겠습니까?")){
  //     localStorage.clear()
  //   }
  // }, [])

  return <>메인입니다</>;
};

export default LoginPage;
